import React from 'react';
import { notification } from 'antd';
import styled from 'styled-components';
import { ArgsProps } from 'antd/lib/notification/interface';

const EmptyDescription = styled.div`
  margin-top: -0.75rem;
`;

type NotificationProps = ArgsProps;

const openSuccessNotification = (config: NotificationProps): void => {
  notification.success({
    ...config,
    message: <>{config.message}</>,
    description: config.description ? <>{config.description}</> : <EmptyDescription />,
    duration: 10,
    onClick: () => {
      notification.destroy();
    },
  });
};

const openInfoNotification = (config: NotificationProps): void => {
  notification.info({
    ...config,
    message: <>{config.message}</>,
    description: config.description ? <>{config.description}</> : <EmptyDescription />,
    duration: config.duration || 10,
    onClick: () => {
      notification.destroy();
    },
  });
};

const openWarningNotification = (config: NotificationProps): void => {
  notification.warning({
    ...config,
    message: <>{config.message}</>,
    description: config.description ? <>{config.description}</> : <EmptyDescription />,
    duration: 10,
    onClick: () => {
      notification.destroy();
    },
  });
};

const openErrorNotification = (config: NotificationProps): void => {
  notification.error({
    ...config,
    message: <>{config.message}</>,
    description: config.description ? <>{config.description}</> : <EmptyDescription />,
    duration: 10,
    onClick: () => {
      notification.destroy();
    },
  });
};

export const notificationController = {
  success: openSuccessNotification,
  info: openInfoNotification,
  warning: openWarningNotification,
  error: openErrorNotification,
};

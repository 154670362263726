import { gql } from '@apollo/client';

export const fragments = {
  paging: gql`
    fragment Paging on Paging {
      page
      perPage
      pageCount
      totalCount
    }
  `,
  translation: gql`
    fragment Translation on Translation {
      languageCode
      value
    }
  `,
  address: gql`
    fragment Address on Address {
      country {
        ...Country
      }
      zipCode
      city
      state
      address1
      address2
    }
  `,
  country: gql`
    fragment Country on Country {
      countryIsoName
      countryIsoAlpha2
      countryIsoAlpha3
    }
  `,
  users: gql`
    fragment Users on Users {
      items {
        ...User
      }
      paging {
        ...Paging
      }
    }
  `,
  user: gql`
    fragment User on User {
      id
      email

      firstName
      lastName
      mobileNumber

      country
      zipCode
      city
      street

      settings {
        ...UserSettings
      }
      officeManagerId
      deleted

      role
      excludedPermissions

      createdDate
      lastModifiedDate
    }
  `,
  userSettings: gql`
    fragment UserSettings on UserSettings {
      languageIso6391
    }
  `,
  userNotifications: gql`
    fragment UserNotifications on UserNotifications {
      notifications {
        ...UserNotification
      }
    }
  `,
  userNotification: gql`
    fragment UserNotification on UserNotification {
      id
      type
      createdDate
      lastModifiedDate
      closedDate
      email
    }
  `,
  userNotificationSettings: gql`
    fragment UserNotificationSettings on UserNotificationSettings {
      settings {
        ...UserNotificationSetting
      }
    }
  `,
  userNotificationSetting: gql`
    fragment UserNotificationSetting on UserNotificationSetting {
      email
      type
      app
    }
  `,
  invitations: gql`
    fragment Invitations on Invitations {
      items {
        ...Invitation
      }
      paging {
        ...Paging
      }
    }
  `,
  invitation: gql`
    fragment Invitation on Invitation {
      code
      usedBy
      usedDate
      email
      role
      excludedPermissions

      createdBy
      createdDate
      lastModifiedDate
    }
  `,
  userEvents: gql`
    fragment UserEvents on UserEvents {
      items {
        ...UserEvent
      }
      paging {
        ...Paging
      }
    }
  `,
  userEvent: gql`
    fragment UserEvent on UserEvent {
      type
      clientMetadata
      userMetadata
      eventMetadata
      createdDate
    }
  `,
  userOnlineStatus: gql`
    fragment UserOnlineStatus on UserOnlineStatus {
      userId
      online
      createdDate
      lastModifiedDate
    }
  `,
  pair: gql`
    fragment Pair on Pair {
      first
      second
    }
  `,
  triple: gql`
    fragment Triple on Triple {
      first
      second
      third
    }
  `,
};

export const me = gql`
  query {
    me {
      ...User
    }
  }
  ${fragments.user}
  ${fragments.userSettings}
`;

export const getUser = gql`
  query ($id: String!) {
    getUser(id: $id) {
      ...User
    }
  }
  ${fragments.user}
  ${fragments.userSettings}
`;

export const getUsers = gql`
  query ($email: String, $paging: SearchPaging!, $sorting: Sorting, $searchCriteria: UserSearchCriteria) {
    getUsers(email: $email, paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...Users
    }
  }
  ${fragments.users}
  ${fragments.user}
  ${fragments.userSettings}
  ${fragments.paging}
`;

export const getAllUserNotifications = gql`
  query ($email: String) {
    getAllUserNotifications(email: $email) {
      ...UserNotifications
    }
  }
  ${fragments.userNotifications}
  ${fragments.userNotification}
`;

export const getActiveUserNotifications = gql`
  query ($email: String) {
    getActiveUserNotifications(email: $email) {
      ...UserNotifications
    }
  }
  ${fragments.userNotifications}
  ${fragments.userNotification}
`;

export const getUserNotificationSettings = gql`
  query ($email: String) {
    getUserNotificationSettings(email: $email) {
      ...UserNotificationSettings
    }
  }
  ${fragments.userNotificationSettings}
  ${fragments.userNotificationSetting}
`;

export const getInvitations = gql`
  query ($email: String, $paging: SearchPaging!, $sorting: Sorting, $searchCriteria: InvitationSearchCriteria) {
    getInvitations(email: $email, paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...Invitations
    }
  }
  ${fragments.invitations}
  ${fragments.invitation}
  ${fragments.paging}
`;

export const getUserEvents = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $email: String!) {
    getUserEvents(paging: $paging, sorting: $sorting, email: $email) {
      ...UserEvents
    }
  }
  ${fragments.userEvents}
  ${fragments.userEvent}
  ${fragments.paging}
`;

export const getUserOnlineStatus = gql`
  query ($email: String!) {
    getUserOnlineStatus(email: $email) {
      ...UserOnlineStatus
    }
  }
  ${fragments.userOnlineStatus}
`;

export const getOnlineStatuses = gql`
  query ($userIds: [String!]!) {
    getOnlineStatuses(userIds: $userIds) {
      ...UserOnlineStatus
    }
  }
  ${fragments.userOnlineStatus}
`;

import React, { createContext, FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { updateUserOnlineStatus } from 'graphql/mutations';
import { PlatformConfig, User, Users } from 'types/types';
import { getUsers, me } from 'graphql/queries';
import { useDispatch } from 'react-redux';
import { userLoaded } from 'slices/authSlice';
import logger from 'utils/logger/logger';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { TablesConfig } from 'types/config/configTypes';
import { getTablesConfig } from 'graphql/config/configQueries';
import {
  agenciesSet,
  courseTypesSet,
  holidaysSet,
  officeManagersSet,
  priceConfigsSet,
  priceYearsSet,
  tablesConfigSet,
  usersSet,
} from 'slices/configSlice';
import { Agencies } from 'types/config/agencyTypes';
import { getAgencies } from 'graphql/config/agencyQueries';
import { OrderBy, SearchPaging, Sorting } from 'types/inputTypes.d';
import { getOfficeManagers } from 'graphql/config/officeManagerQueries';
import { followupTemplatesSet } from 'slices/marketingSlice';
import { Teachers } from 'types/academic/teacherTypes';
import { getTeachers } from 'graphql/academic/teacherQueries';
import { classroomsSet, courseGroupTypesSet, teachersSet } from 'slices/academicSlice';
import { getCourseTypes } from 'graphql/config/course/courseTypeQueries';
import { CourseTypes } from 'types/config/course/courseTypes';
import { AccommodationBookingRoomTypes } from 'types/sales/accommodation/accommodationRoomTypes';
import { FollowupTemplates, FollowupTemplateSearchCriteria } from 'types/marketing/followupTemplateTypes';
import { getFollowupTemplates } from 'graphql/marketing/followupTemplateQueries';
import { AccommodationBookingTypes } from 'types/sales/accommodation/accommodationBookingTypeTypes';
import { getAccommodationBookingTypes } from 'graphql/sales/accommodation/accommodationBookingTypeQueries';
import { AccommodationBookingFoodTypes } from 'types/sales/accommodation/accommodationBookingFoodTypes';
import { getAccommodationBookingRoomTypes } from 'graphql/sales/accommodation/accommodationBookingRoomTypeQueries';
import {
  accommodationBookingFoodTypesSet,
  accommodationBookingRoomTypesSet,
  accommodationBookingTypesSet,
} from 'slices/salesSlice';
import { getAccommodationBookingFoodTypes } from 'graphql/sales/accommodation/accommodationBookingFoodTypeQueries';
import { getAccommodationProviders } from 'graphql/accommodation/accommodationProviderQueries';
import {
  AccommodationProviderType,
  AccommodationProviderTypeType,
} from 'types/accommodation/accommodationProviderTypes';
import { getHostFamilies } from 'graphql/accommodation/hostFamilyQueries';
import { HostFamily } from 'types/accommodation/hostFamilyTypes';
import { hostFamiliesSet, offsiteProvidersSet, onsiteProvidersSet } from 'slices/accommodationSlice';
import { getPriceYears } from 'graphql/config/priceYearQueries';
import { PriceYear } from 'types/config/priceYearTypes';
import { getPriceConfigs } from 'graphql/config/priceConfigQueries';
import { PriceConfigs } from 'types/config/priceConfigTypes';
import { OfficeManagers } from 'types/config/officeManagerTypes';
import { Classrooms } from 'types/academic/classroomTypes';
import { getClassrooms } from 'graphql/academic/classroomQueries';
import { getCourseGroupTypes } from 'graphql/academic/courseGroupTypeQueries';
import { CourseGroupTypes } from 'types/academic/courseGroupTypeTypes';
import { Holidays } from 'types/config/holidayTypes';
import { getHolidays } from 'graphql/config/holidayQueries';
import { getPlatformConfig } from '../graphql/platformQueries';

export interface UserContextProps {
  loading: boolean;

  fetchUser: () => void;
  fetchCourseGroupTypesForRedux: () => void;
  fetchTeachersForRedux: () => void;
  fetchClassroomsForRedux: () => void;
  fetchAgenciesForRedux: () => void;
  fetchUsersForRedux: () => void;
  fetchOfficeManagersForRedux: () => void;
  fetchCourseTypesForRedux: () => void;
  fetchPriceYearsForRedux: () => void;
  fetchPriceConfigsForRedux: () => void;
  fetchHolidaysForRedux: () => void;

  fetchAccommodationBookingTypesForRedux: () => void;
  fetchAccommodationBookingRoomTypesForRedux: () => void;
  fetchAccommodationBookingFoodTypesForRedux: () => void;

  fetchFollowupTemplatesForRedux: () => void;

  fetchAccommodationProvidersForRedux: () => void;
  fetchHostFamiliesForRedux: () => void;
}

export const UserContext = createContext<UserContextProps | null>(null);

/**
 * Provides logged-in user and methods to fetch it.
 * Updates things that depend on the user.
 */
const UserProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const [getMe] = useLazyQuery<{ me?: User }>(me);
  const [trackStatus] = useMutation<{ updateUserOnlineStatus: boolean }>(updateUserOnlineStatus);

  const [doGetPlatformConfig, { loading: loadingPlatformConfig }] = useLazyQuery<{
    getPlatformConfig: PlatformConfig;
  }>(getPlatformConfig);

  const [doGetTablesConfig, { data: dataTablesConfig }] = useLazyQuery<{
    getTablesConfig?: TablesConfig;
  }>(getTablesConfig);
  const [doGetCourseGroupTypes, { data: dataCourseGroupTypes }] = useLazyQuery<{
    getCourseGroupTypes: CourseGroupTypes;
  }>(getCourseGroupTypes);
  const [doGetTeachers, { data: dataTeachers }] = useLazyQuery<{ getTeachers: Teachers }>(getTeachers);
  const [doGetClassrooms, { data: dataClassrooms }] = useLazyQuery<{ getClassrooms?: Classrooms }>(getClassrooms);
  const [doGetAgencies, { data: dataAgencies }] = useLazyQuery<{ getAgencies: Agencies }>(getAgencies);
  const [doGetOfficeManagers, { data: dataOfficeManagers }] = useLazyQuery<{
    getOfficeManagers: OfficeManagers;
  }>(getOfficeManagers);
  const [doGetUsers, { data: dataUsers }] = useLazyQuery<{
    getUsers: Users;
  }>(getUsers);
  const [doGetCourseTypes, { data: dataCourseTypes }] = useLazyQuery<{
    getCourseTypes: CourseTypes;
  }>(getCourseTypes);
  const [doGetPriceYears, { data: dataPriceYears }] = useLazyQuery<{
    getPriceYears: PriceYear[];
  }>(getPriceYears);
  const [doGetPriceConfigs, { data: dataPriceConfigs }] = useLazyQuery<{
    getPriceConfigs: PriceConfigs;
  }>(getPriceConfigs);
  const [doGetHolidays, { data: dataHolidays }] = useLazyQuery<{ getHolidays?: Holidays }>(getHolidays);

  const [doGetAccommodationBookingTypes, { data: dataAccommodationBookingTypes }] = useLazyQuery<{
    getAccommodationBookingTypes: AccommodationBookingTypes;
  }>(getAccommodationBookingTypes);
  const [doGetAccommodationBookingRoomTypes, { data: dataAccommodationBookingRoomTypes }] = useLazyQuery<{
    getAccommodationBookingRoomTypes: AccommodationBookingRoomTypes;
  }>(getAccommodationBookingRoomTypes);
  const [doGetAccommodationBookingFoodTypes, { data: dataAccommodationBookingFoodTypes }] = useLazyQuery<{
    getAccommodationBookingFoodTypes: AccommodationBookingFoodTypes;
  }>(getAccommodationBookingFoodTypes);

  const [doGetFollowupTemplates, { data: dataFollowupTemplates }] = useLazyQuery<{
    getFollowupTemplates: FollowupTemplates;
  }>(getFollowupTemplates);

  const [doGetAccommodationProviders, { data: dataAccommodationProviders }] = useLazyQuery<{
    getAccommodationProviders: AccommodationProviderType[];
  }>(getAccommodationProviders);

  const [doGetHostFamilies, { data: dataHostFamilies }] = useLazyQuery<{
    getHostFamilies: HostFamily[];
  }>(getHostFamilies);

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  // ******************* useCallback *******************

  const fetchUser = useCallback(() => {
    getMe()
      .then((result) => {
        const user = result?.data?.me;
        if (user) {
          dispatch(userLoaded(user));
          setUser(user);
        }
      })
      .finally(() => setLoading(false));
  }, [dispatch, getMe]);

  const fetchCourseGroupTypesForRedux = useCallback(() => {
    doGetCourseGroupTypes({ variables: { paging: { page: 0, perPage: 500 } as SearchPaging } });
  }, [doGetCourseGroupTypes]);

  const fetchTeachersForRedux = useCallback(() => {
    doGetTeachers({
      variables: {
        paging: { page: 0, perPage: 500 } as SearchPaging,
        sorting: { orderBy: [OrderBy.FirstNameAsc] } as Sorting,
      },
    });
  }, [doGetTeachers]);

  const fetchClassroomsForRedux = useCallback(() => {
    doGetClassrooms({
      variables: {
        paging: { page: 0, perPage: 500 } as SearchPaging,
        sorting: { orderBy: [OrderBy.NameAsc] } as Sorting,
      },
    });
  }, [doGetClassrooms]);

  const fetchAgenciesForRedux = useCallback(() => {
    doGetAgencies({ variables: { paging: { page: 0, perPage: 500 } as SearchPaging } });
  }, [doGetAgencies]);

  const fetchOfficeManagersForRedux = useCallback(() => {
    doGetOfficeManagers({
      variables: {
        paging: { page: 0, perPage: 500 } as SearchPaging,
        sorting: { orderBy: [OrderBy.NameAsc] } as Sorting,
      },
    });
  }, [doGetOfficeManagers]);

  const fetchUsersForRedux = useCallback(() => {
    doGetUsers({ variables: { paging: { page: 0, perPage: 500 } as SearchPaging } });
  }, [doGetUsers]);

  const fetchCourseTypesForRedux = useCallback(() => {
    doGetCourseTypes({
      variables: {
        paging: { page: 0, perPage: 500 } as SearchPaging,
        sorting: { orderBy: [OrderBy.OrderNumberAsc] } as Sorting,
      },
    });
  }, [doGetCourseTypes]);

  const fetchPriceYearsForRedux = useCallback(() => {
    doGetPriceYears();
  }, [doGetPriceYears]);

  const fetchPriceConfigsForRedux = useCallback(() => {
    doGetPriceConfigs({ variables: { paging: { page: 0, perPage: 500 } as SearchPaging } });
  }, [doGetPriceConfigs]);

  const fetchHolidaysForRedux = useCallback(() => {
    doGetHolidays({ variables: { sorting: { orderBy: [OrderBy.DateDesc] } as Sorting } });
  }, [doGetHolidays]);

  const fetchAccommodationBookingTypesForRedux = useCallback(() => {
    doGetAccommodationBookingTypes({ variables: { paging: { page: 0, perPage: 500 } as SearchPaging } });
  }, [doGetAccommodationBookingTypes]);

  const fetchAccommodationBookingRoomTypesForRedux = useCallback(() => {
    doGetAccommodationBookingRoomTypes({ variables: { paging: { page: 0, perPage: 500 } as SearchPaging } });
  }, [doGetAccommodationBookingRoomTypes]);

  const fetchAccommodationBookingFoodTypesForRedux = useCallback(() => {
    doGetAccommodationBookingFoodTypes({ variables: { paging: { page: 0, perPage: 500 } as SearchPaging } });
  }, [doGetAccommodationBookingFoodTypes]);

  const fetchFollowupTemplatesForRedux = useCallback(() => {
    doGetFollowupTemplates({
      variables: {
        paging: { page: 0, perPage: 500 } as SearchPaging,
        searchCriteria: { active: true } as FollowupTemplateSearchCriteria,
      },
    });
  }, [doGetFollowupTemplates]);

  const fetchAccommodationProvidersForRedux = useCallback(() => {
    doGetAccommodationProviders({ variables: { searchCriteria: { includeDisabled: true } } });
  }, [doGetAccommodationProviders]);

  const fetchHostFamiliesForRedux = useCallback(() => {
    doGetHostFamilies();
  }, [doGetHostFamilies]);

  // online tracking is done by BE
  const trackOffline = useCallback(() => {
    trackStatus({ variables: { online: false } }).catch((e) => logger.error(`Error updating user online status: ${e}`));
  }, [trackStatus]);

  // ******************* useEffect *******************

  // initial user fetch
  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  // load all needed configuration if user is present
  useEffect(() => {
    if (!user) return;

    doGetPlatformConfig().then((result) => {
      const config = result?.data?.getPlatformConfig;
      if (config) {
        dispatch(courseGroupTypesSet(config.courseGroupTypes));
        dispatch(courseTypesSet(config.courseTypes));
        dispatch(teachersSet(config.teachers));
        dispatch(classroomsSet(config.classrooms));

        dispatch(accommodationBookingTypesSet(config.accBookingTypes));
        dispatch(accommodationBookingRoomTypesSet(config.accBookingRoomTypes));
        dispatch(accommodationBookingFoodTypesSet(config.accBookingFoodTypes));
        dispatch(
          onsiteProvidersSet(
            config.accProviders.filter(({ type }) => type === AccommodationProviderTypeType.ONSITE) || [],
          ),
        );
        dispatch(
          offsiteProvidersSet(
            config.accProviders.filter(({ type }) => type === AccommodationProviderTypeType.OFFSITE) || [],
          ),
        );
        dispatch(hostFamiliesSet(config.hostFamilies));

        dispatch(followupTemplatesSet(config.followupTemplates));

        dispatch(agenciesSet(config.agencies));
        dispatch(officeManagersSet(config.officeManagers));
        dispatch(usersSet(config.users));
        dispatch(priceYearsSet(config.priceYears));
        dispatch(priceConfigsSet(config.priceConfigs));
        dispatch(holidaysSet(config.holidays));
      }
    });
  }, [dispatch, doGetPlatformConfig, user]);

  // update language dependant things
  useEffect(() => {
    if (user && user?.settings?.languageIso6391 !== i18n.language) {
      i18n.changeLanguage(user.settings.languageIso6391);

      // our week starts always on Monday
      moment.locale(user.settings.languageIso6391, {
        week: {
          dow: 1,
        },
      });
    }
  }, [i18n, user]);

  // tables config
  useEffect(() => {
    user && doGetTablesConfig();
  }, [doGetTablesConfig, user]);
  useEffect(() => {
    if (dataTablesConfig?.getTablesConfig) {
      dispatch(tablesConfigSet(dataTablesConfig.getTablesConfig));
    }
  }, [dataTablesConfig, dispatch]);

  // courseGroupTypes
  // useEffect(() => {
  //   user && fetchCourseGroupTypesForRedux();
  // }, [fetchCourseGroupTypesForRedux, user]);
  useEffect(() => {
    if (dataCourseGroupTypes?.getCourseGroupTypes) {
      dispatch(courseGroupTypesSet(dataCourseGroupTypes?.getCourseGroupTypes?.items || []));
    }
  }, [dataCourseGroupTypes?.getCourseGroupTypes, dispatch]);

  // // teachers
  // useEffect(() => {
  //   user && fetchTeachersForRedux();
  // }, [fetchTeachersForRedux, user]);
  useEffect(() => {
    if (dataTeachers?.getTeachers) {
      dispatch(teachersSet(dataTeachers?.getTeachers?.items || []));
    }
  }, [dataTeachers?.getTeachers, dispatch]);

  // // classrooms
  // useEffect(() => {
  //   user && fetchClassroomsForRedux();
  // }, [fetchClassroomsForRedux, user]);
  useEffect(() => {
    if (dataClassrooms?.getClassrooms) {
      dispatch(classroomsSet(dataClassrooms?.getClassrooms?.items || []));
    }
  }, [dataClassrooms?.getClassrooms, dispatch]);

  // // agencies
  // useEffect(() => {
  //   user && fetchAgenciesForRedux();
  // }, [fetchAgenciesForRedux, user]);
  useEffect(() => {
    if (dataAgencies?.getAgencies) {
      dispatch(agenciesSet(dataAgencies?.getAgencies?.items || []));
    }
  }, [dataAgencies?.getAgencies, dispatch]);

  // // office managers
  // useEffect(() => {
  //   user && fetchOfficeManagersForRedux();
  // }, [fetchOfficeManagersForRedux, user]);
  useEffect(() => {
    if (dataOfficeManagers?.getOfficeManagers) {
      dispatch(officeManagersSet(dataOfficeManagers?.getOfficeManagers?.items || []));
    }
  }, [dataOfficeManagers?.getOfficeManagers, dispatch]);

  // // users
  // useEffect(() => {
  //   user && fetchUsersForRedux();
  // }, [fetchUsersForRedux, user]);
  useEffect(() => {
    if (dataUsers?.getUsers) {
      dispatch(usersSet(dataUsers?.getUsers?.items || []));
    }
  }, [dataUsers?.getUsers, dispatch]);

  // // course types
  // useEffect(() => {
  //   user && fetchCourseTypesForRedux();
  // }, [fetchCourseTypesForRedux, user]);
  useEffect(() => {
    if (dataCourseTypes?.getCourseTypes) {
      dispatch(courseTypesSet(dataCourseTypes?.getCourseTypes?.items || []));
    }
  }, [dataCourseTypes?.getCourseTypes, dispatch]);

  // // price years
  // useEffect(() => {
  //   user && fetchPriceYearsForRedux();
  // }, [fetchPriceYearsForRedux, user]);
  useEffect(() => {
    if (dataPriceYears?.getPriceYears) {
      dispatch(priceYearsSet(dataPriceYears?.getPriceYears || []));
    }
  }, [dataPriceYears?.getPriceYears, dispatch]);

  // // price configs
  // useEffect(() => {
  //   user && fetchPriceConfigsForRedux();
  // }, [fetchPriceConfigsForRedux, user]);
  useEffect(() => {
    if (dataPriceConfigs?.getPriceConfigs) {
      dispatch(priceConfigsSet(dataPriceConfigs?.getPriceConfigs?.items || []));
    }
  }, [dataPriceConfigs?.getPriceConfigs, dispatch]);

  // // holiday
  // useEffect(() => {
  //   user && fetchHolidaysForRedux();
  // }, [fetchHolidaysForRedux, user]);
  useEffect(() => {
    if (dataHolidays?.getHolidays) {
      dispatch(holidaysSet(dataHolidays?.getHolidays?.items || []));
    }
  }, [dataHolidays?.getHolidays, dispatch]);

  // // accommodation types
  // useEffect(() => {
  //   user && fetchAccommodationBookingTypesForRedux();
  // }, [fetchAccommodationBookingTypesForRedux, user]);
  useEffect(() => {
    if (dataAccommodationBookingTypes?.getAccommodationBookingTypes) {
      dispatch(accommodationBookingTypesSet(dataAccommodationBookingTypes?.getAccommodationBookingTypes?.items || []));
    }
  }, [dataAccommodationBookingTypes?.getAccommodationBookingTypes, dispatch]);

  // // accommodation room types
  // useEffect(() => {
  //   user && fetchAccommodationBookingRoomTypesForRedux();
  // }, [fetchAccommodationBookingRoomTypesForRedux, user]);
  useEffect(() => {
    if (dataAccommodationBookingRoomTypes?.getAccommodationBookingRoomTypes) {
      dispatch(
        accommodationBookingRoomTypesSet(
          dataAccommodationBookingRoomTypes?.getAccommodationBookingRoomTypes?.items || [],
        ),
      );
    }
  }, [dataAccommodationBookingRoomTypes?.getAccommodationBookingRoomTypes, dispatch]);

  // // accommodation food types
  // useEffect(() => {
  //   user && fetchAccommodationBookingFoodTypesForRedux();
  // }, [fetchAccommodationBookingFoodTypesForRedux, user]);
  useEffect(() => {
    if (dataAccommodationBookingFoodTypes?.getAccommodationBookingFoodTypes) {
      dispatch(
        accommodationBookingFoodTypesSet(
          dataAccommodationBookingFoodTypes?.getAccommodationBookingFoodTypes?.items || [],
        ),
      );
    }
  }, [dataAccommodationBookingFoodTypes?.getAccommodationBookingFoodTypes, dispatch]);

  // // followup templates
  // useEffect(() => {
  //   user && fetchFollowupTemplatesForRedux();
  // }, [fetchFollowupTemplatesForRedux, user]);
  useEffect(() => {
    if (dataFollowupTemplates?.getFollowupTemplates) {
      dispatch(followupTemplatesSet(dataFollowupTemplates?.getFollowupTemplates?.items || []));
    }
  }, [dataFollowupTemplates?.getFollowupTemplates, dispatch]);

  // // accommodation providers
  // useEffect(() => {
  //   user && fetchAccommodationProvidersForRedux();
  // }, [fetchAccommodationProvidersForRedux, user]);
  useEffect(() => {
    if (dataAccommodationProviders?.getAccommodationProviders) {
      dispatch(
        onsiteProvidersSet(
          dataAccommodationProviders?.getAccommodationProviders?.filter(
            ({ type }) => type === AccommodationProviderTypeType.ONSITE,
          ) || [],
        ),
      );
      dispatch(
        offsiteProvidersSet(
          dataAccommodationProviders?.getAccommodationProviders?.filter(
            ({ type }) => type === AccommodationProviderTypeType.OFFSITE,
          ) || [],
        ),
      );
    }
  }, [dataAccommodationProviders?.getAccommodationProviders, dispatch]);

  // // host families
  // useEffect(() => {
  //   user && fetchHostFamiliesForRedux();
  // }, [fetchHostFamiliesForRedux, user]);
  useEffect(() => {
    if (dataHostFamilies?.getHostFamilies) {
      dispatch(hostFamiliesSet(dataHostFamilies?.getHostFamilies || []));
    }
  }, [dataHostFamilies?.getHostFamilies, dispatch]);

  // track user's offline status when browser or tab is closed
  useEffect(() => {
    window.addEventListener('beforeunload', trackOffline, true);
    return () => window.removeEventListener('beforeunload', trackOffline, true);
  }, [trackOffline]);

  const value = {
    loading: loading || loadingPlatformConfig,
    // loading:
    //   loading ||
    //   loadingTablesConfig ||
    //   loadingTeachers ||
    //   loadingAgencies ||
    //   loadingOfficeManagers ||
    //   loadingCourseTypes ||
    //   loadingAccommodationBookingTypes ||
    //   loadingAccommodationBookingRoomTypes ||
    //   loadingAccommodationBookingFoodTypes ||
    //   loadingReceiptTemplates,
    fetchUser,
    fetchCourseGroupTypesForRedux,
    fetchTeachersForRedux,
    fetchClassroomsForRedux,
    fetchAgenciesForRedux,
    fetchOfficeManagersForRedux,
    fetchUsersForRedux,
    fetchCourseTypesForRedux,
    fetchPriceYearsForRedux,
    fetchPriceConfigsForRedux,
    fetchHolidaysForRedux,

    fetchAccommodationBookingTypesForRedux,
    fetchAccommodationBookingRoomTypesForRedux,
    fetchAccommodationBookingFoodTypesForRedux,

    fetchFollowupTemplatesForRedux,

    fetchAccommodationProvidersForRedux,
    fetchHostFamiliesForRedux,
  } as UserContextProps;

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;

export const primary = '#FA8C16';
export const danger = '#F5222D';
export const white = '#FFFFFF';
export const purple = '#531dab';
export const blue = '#0958d9';
export const red = '#cf1322';
export const colorBgContainer = '#FFFFFF';
export const colorLinkHover = '#FFA940';
export const colorPrimaryBorder = '#FFD591';
export const colorPrimaryActive = '#D46B08';

export const siderWidth = '16rem';
export const siderCollapsedWidth = '4rem';

import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries';

export const teacherFragments = {
  teacher: gql`
    fragment Teacher on Teacher {
      id
      email
      firstName
      lastName
      phoneNumber

      country
      zipCode
      city
      street

      coordinator
      conversationClassAllowed
      beginnerClassAllowed
      virtualClassAllowed
      virtualClassId
      activityCoordinator
      activity
      feedbackFormUrl

      minHoursPerWeek
      maxHoursPerWeek

      workingHourStart
      workingHourEnd

      contractedHours
      collaboration

      deleted

      createdDate
      lastModifiedDate
    }
  `,
  teachers: gql`
    fragment Teachers on Teachers {
      items {
        ...Teacher
      }
      paging {
        ...Paging
      }
    }
  `,
};

export const getTeacher = gql`
  query getTeacher($id: String!) {
    getTeacher(id: $id) {
      ...Teacher
    }
  }
  ${teacherFragments.teacher}
`;

export const getTeachers = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $searchCriteria: TeacherSearchCriteria) {
    getTeachers(paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...Teachers
    }
  }
  ${teacherFragments.teachers}
  ${teacherFragments.teacher}
  ${fragments.paging}
`;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FollowupTemplate } from 'types/marketing/followupTemplateTypes';

export type MarketingSliceState = {
  followupTemplates: FollowupTemplate[];
};

const initialState = {
  followupTemplates: [],
} as MarketingSliceState;

const marketingSlice = createSlice({
  name: 'marketing',
  initialState: initialState,
  reducers: {
    followupTemplatesSet(state, action: PayloadAction<FollowupTemplate[]>) {
      state.followupTemplates = action.payload;
    },
  },
});

export const { followupTemplatesSet } = marketingSlice.actions;

export default marketingSlice.reducer;

import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries';

export const platformFragments = {
  discount: gql`
    fragment Discount on Discount {
      type
      amount
    }
  `,
  file: gql`
    fragment File on File {
      name
      content
    }
  `,
  timePeriod: gql`
    fragment TimePeriod on TimePeriod {
      start
      end
    }
  `,
  platformMetrics: gql`
    fragment PlatformMetrics on PlatformMetrics {
      date

      profiles
      registrationsYesterday
      registrationsLastWeek
      registrationsLastMonth

      usersWithConfirmedEmail
      deletedProfiles

      dau
      wau
      mau
      logins
      loginsYesterday
      loginsLastWeek
      loginsLastMonth

      usersWithFreemiumPlan
      usersWithoutPlan
      usersWithPaidPlan
      usersWithMonthlySubscription
      usersWithYearlySubscription
      cancelledPlans

      usersWithTwoFA
      usersWithEn
      usersWithDe

      usersWithUserRole
      usersWithAdminRole

      socialRegistrations
    }
  `,
  platformConfig: gql`
    fragment PlatformConfig on PlatformConfig {
      courseGroupTypes {
        id
        name
        courseTypeIds
        teachersNumber
        orderNumber

        timePeriods {
          start
          end
        }
        studyDays
        hoursInLesson

        levels

        weeklyTimetableFileName
        weeklyTimetableCourseName

        disabled
        deleted

        createdDate
        lastModifiedDate
      }
      courseTypes {
        id

        name
        nameEs

        isGroup
        orderNumber

        live

        lessonsNumber
        privateLessonsNumber
        lessonsNumberDesc

        startTime
        endTime

        websitePage
        websitePages {
          languageCode
          value
        }
        noAccommodation
        noRegFee

        websitePrimaryId
        websiteCourseId

        createdDate
        lastModifiedDate
      }
      teachers {
        id
        email
        firstName
        lastName
        phoneNumber

        country
        zipCode
        city
        street

        coordinator
        conversationClassAllowed
        beginnerClassAllowed
        virtualClassAllowed
        virtualClassId
        activityCoordinator
        activity
        feedbackFormUrl

        minHoursPerWeek
        maxHoursPerWeek

        workingHourStart
        workingHourEnd

        contractedHours
        collaboration

        deleted

        createdDate
        lastModifiedDate
      }
      classrooms {
        id
        name
        building
        floor
        recommendedStudentsNumber
        maxStudentsNumber

        disabled
        deleted

        createdDate
        lastModifiedDate
      }

      accBookingTypes {
        id
        name
        nameEn

        createdDate
        lastModifiedDate
      }
      accBookingRoomTypes {
        id
        name
        nameEn

        createdDate
        lastModifiedDate
      }
      accBookingFoodTypes {
        id
        name
        nameEn

        createdDate
        lastModifiedDate
      }
      accProviders {
        id
        type
        name
        disabled

        contactPerson
        email
        phoneNumber
        comment

        country
        zipCode
        city
        street

        locations {
          id
          providerId
          providerType
          disabled

          country
          zipCode
          city
          street

          latitude
          longitude

          distanceInMin
          description
          mapLink

          flats {
            id
            locationId
            providerType
            disabled

            floor
            name
            description
            descriptions {
              languageCode
              value
            }
            roomsNumber
            bathroomsNumber
            ac

            orderNumber

            rooms {
              id
              flatId
              providerType
              disabled

              name
              description
              descriptions {
                languageCode
                value
              }
              sizeInSqm
              maxStudents
              roomTypeId

              bedsNumber
              bedType

              pricePerWeekSingle
              pricePerWeekShared
              providerPricePerWeek

              usageType
              gender

              ac
              bathroom
              balcony
              interior
              exterior
              hairdryer
              bedsheets
              wifi

              orderNumber
            }
          }
        }
      }
      hostFamilies {
        id
        name
        disabled

        phoneNumber
        secondPhoneNumber
        email

        address

        latitude
        longitude

        petsGroup
        petsDescription
        children

        food
        vegetarian

        walkingDistance
        walkingDistanceGroup
        transportDistanceInMin

        area
        comment
        desc
        descSpanish
        descByTrain
        descByBus
        descByTaxi
        descToSchool
        mapLink

        rooms {
          id
          familyId

          name
          disabled
          roomTypeId
          description
          descriptions {
            languageCode
            value
          }
          sizeInSqm

          ac
          bathroom
          balcony
          interior
          exterior
          hairdryer
          bedsheets
          wifi

          pricePerWeek
          providerPricePerWeek
        }
      }

      followupTemplates {
        id
        key

        group
        name

        description
        active

        deleted

        createdDate
        lastModifiedDate
        createdBy
        lastModifiedBy
      }

      agencies {
        id
        code
        fullName
        email
        phoneNumber
        country
        zipCode
        city
        street
        vatId

        likeCamino
        registrationFee
        registrationFeeCommission
        courseCommission
        accommodationCommission
        commissionExceptions {
          courseTypeId
          accTypeId
          accRoomId
          accFoodId
          commission

          accommodation
        }

        deleted
        disabled

        createdDate
        lastModifiedDate
      }
      officeManagers {
        id
        name
        language
        deleted

        createdDate
        lastModifiedDate
      }
      users {
        id
        email

        firstName
        lastName
        mobileNumber

        country
        zipCode
        city
        street

        settings {
          languageIso6391
        }
        deleted

        role
        excludedPermissions

        createdDate
        lastModifiedDate
      }
      priceYears {
        id
        name
        active
      }
      priceConfigs {
        id
        year
        type
        amount
      }
      holidays {
        id
        date
        name

        createdDate
        lastModifiedDate
      }
    }
  `,
};

export const getPlatformMetrics = gql`
  query ($fromDate: String, $toDate: String) {
    getPlatformMetrics(fromDate: $fromDate, toDate: $toDate) {
      ...PlatformMetrics
    }
  }
  ${platformFragments.platformMetrics}
`;

export const getPlatformConfig = gql`
  query {
    getPlatformConfig {
      ...PlatformConfig
    }
  }
  ${platformFragments.platformConfig}
`;

export const getBookingsAnalytics = gql`
  query ($searchCriteria: BookingSearchCriteria, $datePeriodType: DatePeriodType!) {
    getBookingsAnalytics(searchCriteria: $searchCriteria, datePeriodType: $datePeriodType) {
      ...Pair
    }
  }
  ${fragments.pair}
`;

export const getMarketingConversionsAnalytics = gql`
  query ($searchCriteria: MarketingConversionSearchCriteria, $datePeriodType: DatePeriodType!) {
    getMarketingConversionsAnalytics(searchCriteria: $searchCriteria, datePeriodType: $datePeriodType) {
      ...Pair
    }
  }
  ${fragments.pair}
`;

export const getPaymentsAnalytics = gql`
  query ($searchCriteria: PaymentSearchCriteria, $datePeriodType: DatePeriodType!) {
    getPaymentsAnalytics(searchCriteria: $searchCriteria, datePeriodType: $datePeriodType) {
      ...Triple
    }
  }
  ${fragments.triple}
`;

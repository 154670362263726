import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries';
import { platformFragments } from 'graphql/platformQueries';

export const courseGroupTypeFragments = {
  courseGroupTypes: gql`
    fragment CourseGroupTypes on CourseGroupTypes {
      items {
        ...CourseGroupType
      }
      paging {
        ...Paging
      }
    }
  `,
  courseGroupType: gql`
    fragment CourseGroupType on CourseGroupType {
      id
      name
      courseTypeIds
      teachersNumber
      orderNumber

      timePeriods {
        ...TimePeriod
      }
      studyDays
      hoursInLesson

      levels

      weeklyTimetableFileName
      weeklyTimetableCourseName
      groupNamePrefix

      disabled
      deleted

      createdDate
      lastModifiedDate
    }
  `,
};

export const getCourseGroupType = gql`
  query getCourseGroupType($id: String!) {
    getCourseGroupType(id: $id) {
      ...CourseGroupType
    }
  }
  ${courseGroupTypeFragments.courseGroupType}
  ${platformFragments.timePeriod}
`;

export const getCourseGroupTypes = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $searchCriteria: CourseGroupTypeSearchCriteria) {
    getCourseGroupTypes(paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...CourseGroupTypes
    }
  }
  ${courseGroupTypeFragments.courseGroupTypes}
  ${courseGroupTypeFragments.courseGroupType}
  ${fragments.paging}
  ${platformFragments.timePeriod}
`;

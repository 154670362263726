import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries';

export const courseTypeFragments = {
  courseType: gql`
    fragment CourseType on CourseType {
      id

      name
      nameEs

      isGroup
      orderNumber

      live

      lessonsNumber
      privateLessonsNumber
      lessonsNumberDesc

      startTime
      endTime

      translations {
        ...Translation
      }
      descriptions {
        ...Translation
      }
      emailDescriptions {
        ...Translation
      }
      categories

      websitePage
      websitePages {
        ...Translation
      }
      noAccommodation
      noRegFee

      websitePrimaryId
      websiteCourseId

      createdDate
      lastModifiedDate
    }
  `,
  courseTypes: gql`
    fragment CourseTypes on CourseTypes {
      items {
        ...CourseType
      }
      paging {
        ...Paging
      }
    }
  `,
};

export const getCourseType = gql`
  query getCourseType($id: Int!) {
    getCourseType(id: $id) {
      ...CourseType
    }
  }
  ${courseTypeFragments.courseType}
  ${fragments.translation}
`;

export const getCourseTypes = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $searchCriteria: CourseTypeSearchCriteria) {
    getCourseTypes(paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...CourseTypes
    }
  }
  ${courseTypeFragments.courseTypes}
  ${courseTypeFragments.courseType}
  ${fragments.translation}
  ${fragments.paging}
`;

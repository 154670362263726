import { CourseGroupType } from 'types/academic/courseGroupTypeTypes';
import { CourseType } from 'types/config/course/courseTypes';
import { Teacher } from 'types/academic/teacherTypes';
import { Classroom } from 'types/academic/classroomTypes';
import { AccommodationBookingType } from 'types/sales/accommodation/accommodationBookingTypeTypes';
import { AccommodationBookingRoomType } from 'types/sales/accommodation/accommodationRoomTypes';
import { AccommodationBookingFoodType } from 'types/sales/accommodation/accommodationBookingFoodTypes';
import { HostFamily } from 'types/accommodation/hostFamilyTypes';
import { AccommodationProviderType } from 'types/accommodation/accommodationProviderTypes';
import { FollowupTemplate } from 'types/marketing/followupTemplateTypes';
import { Agency } from 'types/config/agencyTypes';
import { OfficeManager } from 'types/config/officeManagerTypes';
import { PriceYear } from 'types/config/priceYearTypes';
import { PriceConfig } from 'types/config/priceConfigTypes';
import { Holiday } from 'types/config/holidayTypes';

export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  // BigDecimal: any;
  // OffsetDateTime: any;
  UUID: string;
};

export interface StringMap {
  [key: string]: string | null;
}

interface StringToAnyMap {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface Pair {
  first?: Maybe<Scalars['String']>;
  second?: Maybe<Scalars['String']>;
}

export interface Triple {
  first?: Maybe<Scalars['String']>;
  second?: Maybe<Scalars['String']>;
  third?: Maybe<Scalars['String']>;
}

export interface ReferrerLocationState {
  referrer?: string;
}

// these are default values of React Bootstrap Table2, if they are changed consider using "sizePerPageList" property
export const getTablePageSize = (forAdmin: boolean): number[] => (forAdmin ? [10, 50, 100, 500] : [10, 50, 100]);
export const tablePageSize = [10, 50, 100, 500];
export const adminTablePageSize = [10, 50, 100, 500];

export type Paging = {
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  pageCount: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export const debounceDelay = 500;

export enum LanguageCode {
  EN = 'en',
  ES = 'es',
  DE = 'de',
  IT = 'it',
  PT = 'pt',
  RU = 'ru',
  NL = 'nl',
  SE = 'se',
  FR = 'fr',
  AR = 'ar', // arabic, not Argentina
  TR = 'tr',
  CN = 'cn',
  PL = 'pl',
  KR = 'kr',
  JP = 'jp',
  UA = 'ua',
}

export enum CountryCode {
  UNKNOWN = 'unknown',
  AE = 'ae',
  AT = 'at',
  AU = 'au',
  AF = 'af',
  AM = 'am',
  AO = 'ao',
  AZ = 'az',
  BD = 'bd',
  BF = 'bf',
  BH = 'bh',
  BI = 'bi',
  BJ = 'bj',
  BO = 'bo',
  BT = 'bt',
  BW = 'bw',
  BZ = 'bz',
  CD = 'cd',
  CG = 'cg',
  CF = 'cf',
  CV = 'cv',
  DJ = 'dj',
  DZ = 'dz',
  EC = 'ec',
  SZ = 'sz',
  ET = 'et',
  FJ = 'fj',
  GA = 'ga',
  GH = 'gh',
  GM = 'gm',
  GN = 'gn',
  GQ = 'gq',
  GW = 'gw',
  GY = 'gy',
  HT = 'ht',
  IO = 'io',
  IQ = 'iq',
  JM = 'jm',
  JO = 'jo',
  KE = 'ke',
  KG = 'kg',
  KH = 'kh',
  KM = 'km',
  KP = 'kp',
  LA = 'la',
  LR = 'lr',
  LS = 'ls',
  LY = 'ly',
  MG = 'mg',
  ML = 'ml',
  MM = 'mm',
  MN = 'mn',
  MR = 'mr',
  MV = 'mv',
  MW = 'mw',
  MZ = 'mz',
  NE = 'ne',
  NG = 'ng',
  NP = 'np',
  OM = 'om',
  PG = 'pg',
  RW = 'rw',
  SS = 'ss',
  SD = 'sd',
  SL = 'sl',
  SN = 'sn',
  SO = 'so',
  SR = 'sr',
  ST = 'st',
  TD = 'td',
  TG = 'tg',
  TJ = 'tj',
  TZ = 'tz',
  UG = 'ug',
  YE = 'ye',
  ZM = 'zm',
  ZW = 'zw',
  PS = 'ps',
  AG = 'ag',
  MK = 'mk',
  AD = 'ad',
  AL = 'al',
  AR = 'ar',
  BB = 'bb',
  BN = 'bn',
  BS = 'bs',
  CO = 'co',
  CR = 'cr',
  DM = 'dm',
  FM = 'fm',
  GD = 'gd',
  GS = 'gs',
  GT = 'gt',
  GF = 'gf',
  HN = 'hn',
  KL = 'kl',
  KN = 'kn',
  LC = 'lc',
  ME = 'me',
  MH = 'mh',
  NI = 'ni',
  NR = 'nr',
  PW = 'pw',
  SB = 'sb',
  SM = 'sm',
  SV = 'sv',
  TL = 'tl',
  TO = 'to',
  TT = 'tt',
  TV = 'tv',
  VA = 'va',
  VC = 'vc',
  VE = 've',
  VU = 'vu',
  WS = 'ws',
  AS = 'as',
  MO = 'mo',
  BA = 'ba',
  BE = 'be',
  BG = 'bg',
  BY = 'by',
  HR = 'hr',
  CA = 'ca',
  CI = 'ci',
  CH = 'ch',
  CY = 'cy',
  CM = 'cm',
  CZ = 'cz',
  DK = 'dk',
  DO = 'do',
  EE = 'ee',
  EG = 'eg',
  ES = 'es',
  FI = 'fi',
  FR = 'fr',
  DE = 'de',
  GB = 'gb',
  GR = 'gr',
  HU = 'hu',
  IE = 'ie',
  ID = 'id',
  IS = 'is',
  IT = 'it',
  IR = 'ir',
  LI = 'li',
  LV = 'lv',
  LT = 'lt',
  LU = 'lu',
  MT = 'mt',
  MC = 'mc',
  NL = 'nl',
  NO = 'no',
  PL = 'pl',
  PT = 'pt',
  RO = 'ro',
  RS = 'rs',
  SK = 'sk',
  SI = 'si',
  SE = 'se',
  BR = 'br',
  CL = 'cl',
  CN = 'cn',
  CU = 'cu',
  GE = 'ge',
  HK = 'hk',
  IN = 'in',
  IL = 'il',
  JP = 'jp',
  KZ = 'kz',
  KW = 'kw',
  MX = 'mx',
  MD = 'md',
  MA = 'ma',
  MY = 'my',
  NZ = 'nz',
  PK = 'pk',
  PA = 'pa',
  PY = 'py',
  PE = 'pe',
  PH = 'ph',
  PR = 'pr',
  QA = 'qa',
  RU = 'ru',
  SA = 'sa',
  SC = 'sc',
  SG = 'sg',
  ZA = 'za',
  KR = 'kr',
  LK = 'lk',
  LB = 'lb',
  SY = 'sy',
  TW = 'tw',
  TH = 'th',
  TN = 'tn',
  TR = 'tr',
  TM = 'tm',
  UA = 'ua',
  US = 'us',
  UY = 'uy',
  UZ = 'uz',
  VN = 'vn',
}

export const EU_COUNTRIES = [
  CountryCode.AT,
  CountryCode.BE,
  CountryCode.BG,
  CountryCode.HR,
  CountryCode.CY,
  CountryCode.CZ,
  CountryCode.DK,
  CountryCode.DO,
  CountryCode.EE,
  CountryCode.ES,
  CountryCode.FI,
  CountryCode.FR,
  CountryCode.DE,
  CountryCode.GR,
  CountryCode.HU,
  CountryCode.IE,
  CountryCode.IS,
  CountryCode.IT,
  CountryCode.IR,
  CountryCode.LI,
  CountryCode.LV,
  CountryCode.LT,
  CountryCode.LU,
  CountryCode.MT,
  CountryCode.NL,
  CountryCode.NO,
  CountryCode.PL,
  CountryCode.PT,
  CountryCode.RO,
  CountryCode.SK,
  CountryCode.SI,
  CountryCode.SE,
];

export enum CurrencyCode {
  EUR = 'EUR',
  USD = 'USD',
  GBP = 'GBP',
  CHF = 'CHF',
  AUD = 'AUD',
  PLN = 'PLN',
  CZK = 'CZK',
}

export type Address = {
  country?: Maybe<Country>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
};

export type Country = {
  countryIsoName?: Maybe<Scalars['String']>;
  countryIsoAlpha2?: Maybe<CountryCode>;
  countryIsoAlpha3?: Maybe<Scalars['String']>;
};

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export type Discount = {
  type: DiscountType;
  amount?: Maybe<Scalars['String']>;
};

export enum DiscountType {
  // todo what is: apply reservation if it defined,
  NONE = 'NONE',
  PERCENTAGE = 'PERCENTAGE',
  AMOUNT = 'AMOUNT',
}

export enum PaymentMethod {
  CASH_RECEPTION = 'CASH_RECEPTION',
  CASH_RECEPTION_CAMINO = 'CASH_RECEPTION_CAMINO',
  CASH_RECEPTION_URGELL = 'CASH_RECEPTION_URGELL',

  CARD_RECEPTION = 'CARD_RECEPTION',
  CARD_RECEPTION_CAMINO = 'CARD_RECEPTION_CAMINO',
  CARD_RECEPTION_URGELL = 'CARD_RECEPTION_URGELL',

  BANK_TRANSFER = 'BANK_TRANSFER',
  BANK_TRANSFER_CAMINO = 'BANK_TRANSFER_CAMINO',
  BANK_TRANSFER_URGELL = 'BANK_TRANSFER_URGELL',

  // credit card online
  CARD = 'CARD',
  CARD_BRAINTREE = 'CARD_BRAINTREE',
  CARD_BRAINTREE_CAMINO = 'CARD_BRAINTREE_CAMINO',
  CARD_BRAINTREE_URGELL = 'CARD_BRAINTREE_URGELL',
  CARD_REDSYS = 'CARD_REDSYS',
  CARD_REDSYS_CAMINO = 'CARD_REDSYS_CAMINO',
  CARD_REDSYS_URGELL = 'CARD_REDSYS_URGELL',
  CARD_STRIPE = 'CARD_STRIPE',
  CARD_STRIPE_CAMINO = 'CARD_STRIPE_CAMINO',
  CARD_STRIPE_URGELL = 'CARD_STRIPE_URGELL',
}

export const USED_PAYMENT_METHODS = [
  PaymentMethod.CASH_RECEPTION,
  PaymentMethod.CARD_RECEPTION,
  PaymentMethod.BANK_TRANSFER,
  PaymentMethod.CARD,
];

export type File = {
  name: Scalars['String'];
  content: Scalars['String'];
};

export type TimePeriod = {
  start: Scalars['String'];
  end: Scalars['String'];
};

export type Users = {
  items: Array<User>;
  paging: Paging;
};

export type User = {
  id: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;

  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobileNumber?: Maybe<Scalars['String']>;

  country?: Maybe<CountryCode>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;

  settings: UserSettings;
  officeManagerId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;

  role: SecurityRole;
  excludedPermissions?: Maybe<Array<Scalars['String']>>;

  createdDate: Scalars['String'];
  lastModifiedDate?: Maybe<Scalars['String']>;
};

export type UserSettings = {
  languageIso6391: LanguageCode;
};

export enum SecurityRole {
  OWNER = 'owner',
  ADMINISTRATION = 'administration',
  SALES = 'sales',
  MARKETING = 'marketing',
  ACCOMMODATION = 'accommodation',
  ACADEMIC = 'academic',
  TEACHER = 'teacher',
  ENTERTAINMENT = 'entertainment',
  FINANCE = 'finance',
}

export const CUSTOMER_ROLES = [
  SecurityRole.ADMINISTRATION,
  SecurityRole.SALES,
  SecurityRole.MARKETING,
  SecurityRole.ACCOMMODATION,
  SecurityRole.ACADEMIC,
  SecurityRole.TEACHER,
  SecurityRole.ENTERTAINMENT,
  SecurityRole.FINANCE,
];
export const ADMIN_ROLES = [SecurityRole.OWNER];
export const ALL_ROLES = [...CUSTOMER_ROLES, ...ADMIN_ROLES];

export enum SecurityPermission {
  TEST = 'TEST',
}

export type Translation = {
  languageCode: LanguageCode;
  value: Scalars['String'];
  updated?: Maybe<Scalars['Boolean']>;
};

export type UserNotifications = {
  notifications: Array<UserNotification>;
};

export type UserNotification = {
  id: Scalars<['ID']>;
  type: UserNotificationType;
  createdDate: Scalars<['String']>;
  lastModifiedDate?: Maybe<Scalars<['String']>>;
  closedDate?: Maybe<Scalars<['String']>>;

  // metadata
  email?: Maybe<Scalars<['String']>>;
  bankConnectionId?: Maybe<Scalars<['String']>>;
  bankName?: Maybe<Scalars<['String']>>;
  bankAccountId?: Maybe<Scalars<['String']>>;
  bankAccountName?: Maybe<Scalars<['String']>>;
  bankAccountType?: Maybe<Scalars<['String']>>;
  count?: Maybe<Scalars<['Int']>>;
  fromDate?: Maybe<Scalars<['String']>>;
  toDate?: Maybe<Scalars<['String']>>;
  dateTime?: Maybe<Scalars<['String']>>;
  documentId?: Maybe<Scalars<['String']>>;
  documentName?: Maybe<Scalars<['String']>>;
};

export enum UserNotificationType {
  // general
  PROFILE_CREATED = 'PROFILE_CREATED',
  PROFILE_EMAIL_NOT_CONFIRMED = 'PROFILE_EMAIL_NOT_CONFIRMED',
  PROFILE_EMAIL_CHANGED = 'PROFILE_EMAIL_CHANGED',
  PROFILE_PASSWORD_RESET = 'PROFILE_PASSWORD_RESET',
}

export type UserNotificationSettings = {
  settings: Array<UserNotificationSetting>;
};

export type UserNotificationSetting = {
  type: Scalars['String'];
  email: Scalars['Boolean'];
  app: Scalars['Boolean'];
};

export type Invitations = {
  items: Array<Invitation>;
  paging: Paging;
};

export type Invitation = {
  code: Scalars['ID'];
  usedBy?: Maybe<Scalars['String']>;
  usedDate?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  excludedPermissions?: Maybe<Array<Scalars['String']>>;

  createdBy?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['String']>;
  lastModifiedDate?: Maybe<Scalars['String']>;
};

export type UserEvents = {
  items: Array<UserEvent>;
  paging: Paging;
};

export type UserEvent = {
  type: UserEventType;
  clientMetadata?: Maybe<Array<Scalars['String']>>; // map entries joined via '='
  userMetadata?: Maybe<Array<Scalars['String']>>; // map entries joined via '='
  eventMetadata?: Maybe<Array<Scalars['String']>>; // map entries joined via '='
  createdDate?: Maybe<Scalars['String']>;
};

// only FE-related events are defined
export enum UserEventType {
  PAGE_VIEWED = 'PAGE_VIEWED',

  PROFILE_CREATED = 'PROFILE_CREATED',
  EMAIL_CONFIRMED = 'EMAIL_CONFIRMED', // backend
  PROFILE_UPDATED = 'PROFILE_UPDATED',
  PROFILE_COMPLETED = 'PROFILE_COMPLETED',
  EMAIL_CHANGE_TRIGGERED = 'EMAIL_CHANGE_TRIGGERED',
  EMAIL_CHANGED = 'EMAIL_CHANGED',
  USER_DELETION_TRIGGERED = 'USER_DELETION_TRIGGERED',
  USER_DELETED = 'USER_DELETED', // backend

  USER_LOGGED_IN = 'USER_LOGGED_IN',
  USER_LOGGED_IN_2_FA = 'USER_LOGGED_IN_2_FA',
  USER_LOGGED_OUT = 'USER_LOGGED_OUT',

  PASSWORD_RESET_TRIGGERED = 'PASSWORD_RESET_TRIGGERED', // backend
  PASSWORD_RESET = 'PASSWORD_RESET', // backend
  SETTINGS_PASSWORD_UPDATED = 'PASSWORD_UPDATED',
  SETTINGS_AUTHENTICATION_UPDATED = 'SETTINGS_AUTHENTICATION_UPDATED',

  SUBSCRIBED_TO_UPDATES = 'SUBSCRIBED_TO_UPDATES', // backend
  UNSUBSCRIBED_FROM_UPDATES = 'UNSUBSCRIBED_FROM_UPDATES', // backend

  BILLING_SUBSCRIPTION_TRIGGERED = 'BILLING_SUBSCRIPTION_TRIGGERED',
  BILLING_SUBSCRIPTION_CANCELLED = 'BILLING_SUBSCRIPTION_CANCELLED',

  COURSE_ATTENDANCE_CREATED = 'COURSE_ATTENDANCE_CREATED',
  COURSE_ATTENDANCE_UPDATED = 'COURSE_ATTENDANCE_UPDATED',
  COURSE_ATTENDANCE_DELETED = 'COURSE_ATTENDANCE_DELETED',
}

export type UserOnlineStatus = {
  userId: Scalars['String'];
  online: Scalars['Boolean'];
  createdDate?: Maybe<Scalars['String']>;
  lastModifiedDate?: Maybe<Scalars['String']>;
};

export enum OriginSource {
  EMAIL = 'EMAIL',
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK',
  TELEPHONE = 'TELEPHONE',
  VISIT = 'VISIT',
  REFERENCE = 'REFERENCE',
  ADVERTISEMENT = 'ADVERTISEMENT',
  OTHER = 'OTHER',
}

export enum IdDocumentType {
  PASSPORT = 'PASSPORT',
  NIE = 'NIE',
  ID = 'ID',
  OTHER = 'OTHER',
}

export enum DatePeriodType {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
}

export type PlatformConfig = {
  user: User;

  courseGroupTypes: Array<CourseGroupType>;
  courseTypes: Array<CourseType>;
  teachers: Array<Teacher>;
  classrooms: Array<Classroom>;

  accBookingTypes: Array<AccommodationBookingType>;
  accBookingRoomTypes: Array<AccommodationBookingRoomType>;
  accBookingFoodTypes: Array<AccommodationBookingFoodType>;
  accProviders: Array<AccommodationProviderType>;
  hostFamilies: Array<HostFamily>;

  followupTemplates: Array<FollowupTemplate>;

  agencies: Array<Agency>;
  officeManagers: Array<OfficeManager>;
  users: Array<User>;
  priceYears: Array<PriceYear>;
  priceConfigs: Array<PriceConfig>;
  holidays: Array<Holiday>;
};

import React, { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LOGIN_TWO_FA, URL_QUERY_PARAM_EXPIRED_SESSION, useURLSearchParams } from 'utils/routingUtils';
import { UserEventType } from 'types/types.d';
import useUserTracking from 'hooks/useUserTracking';
import { login, LoginResult } from 'services/auth.service';
import { Button, Checkbox, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { passwordMinLength } from 'utils/userUtils';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import * as S from './Login.styles';
import { useMediaQuery } from 'react-responsive';
import { BREAKPOINTS } from 'components/Breakpoints';
import logger from 'utils/logger/logger';
import { UserContext, UserContextProps } from 'contextProviders/UserProvider';
import { customRules } from 'utils/rulesUtils';
import { notificationController } from 'components/NotificationController';

/**
 * Shows login form for a user to provide basic authentication credentials.
 * Login is possible with email/password or with Google.
 *
 * If logging is with Google a redirect to our OAuth2 endpoint happens where communication of our BE and Google happens,
 * in case of a successful login a redirect to this component with a short-lived refresh token happens.
 * The token is stored in the needed cookie and the page is refreshed with an automatic tokens refresh happening in the BE.
 */
interface Values {
  email: string;
  password: string;
  rememberMe: boolean;
}

const Login = (): JSX.Element => {
  const navigate = useNavigate();
  const trackUserEvent = useUserTracking();
  const { t } = useTranslation();
  const expiredSession = useURLSearchParams().has(URL_QUERY_PARAM_EXPIRED_SESSION);
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINTS.SM });

  const { fetchUser } = useContext(UserContext) as UserContextProps;

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    (values: Values): void => {
      setLoading(true);

      login(values.email, values.password, values.rememberMe)
        .then((result: LoginResult) => {
          if (result && result.loginOk) {
            if (result.twoFA) {
              // send user to the 2FA second step
              navigate(`/${LOGIN_TWO_FA}`, { state: { rememberMe: true } });
            } else {
              trackUserEvent(UserEventType.USER_LOGGED_IN);
              fetchUser();
            }
          } else {
            notificationController.error({
              message: t('common.error'),
            });
            logger.error('could not log in');
          }
        })
        .catch((e) => {
          notificationController.error({
            message: t('common.error'),
          });
          logger.error(`could not log in: ${e}`);
        })
        .finally(() => {
          setLoading(false); // Stop loading
        });
    },
    [fetchUser, navigate, t, trackUserEvent],
  );

  return (
    <S.LoginCard bodyStyle={{ padding: 0 }}>
      {expiredSession && <S.ExpiredSession>{t('public.login.expired_session')}</S.ExpiredSession>}

      <S.TitleAndLink>
        <S.HeaderTitle>{t('public.login.title')}</S.HeaderTitle>
      </S.TitleAndLink>

      <Form
        name="login"
        layout="vertical"
        autoComplete="true"
        initialValues={{ rememberMe: true }}
        onFinish={handleSubmit}
      >
        <S.FormItemLogin
          name="email"
          required={false}
          label={isMobile ? null : t('public.login.email_address')}
          rules={[customRules.required(t('public.login.email_address'), t), customRules.emailRule(t)]}
        >
          <Input
            placeholder={t('common.input')}
            prefix={<MailOutlined style={{ opacity: 0.45, marginRight: '.25rem' }} />}
            autoComplete="true"
          />
        </S.FormItemLogin>

        <S.FormItemLogin
          name="password"
          required={false}
          label={isMobile ? null : t('public.login.password')}
          rules={[customRules.required(t('public.login.password'), t), ...customRules.passwordRule(t)]}
        >
          <Input.Password
            placeholder={t('common.input', { length: passwordMinLength })}
            prefix={<LockOutlined style={{ opacity: 0.45, marginRight: '.25rem' }} />}
            autoComplete="true"
          />
        </S.FormItemLogin>

        <S.ForgotAndRememberWrapper>
          <S.FormItemLogin name="rememberMe" valuePropName="checked">
            <Checkbox>{t('public.login.remember_me')}</Checkbox>
          </S.FormItemLogin>
        </S.ForgotAndRememberWrapper>

        <Button type="primary" block htmlType="submit" loading={loading}>
          {t('public.login.btn')}
        </Button>
      </Form>
    </S.LoginCard>
  );
};

export default Login;
